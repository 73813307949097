import React, { useState, useEffect, Fragment, useRef } from 'react';
import './sass/app.sass';

// img
import papel from './img/papel.png';
import pan from './img/pan.png';
import cubierta from './img/cubierta.png';
import corazon from './img/corazon.png';
import boca02 from './img/boca02.png';
import boca01 from './img/boca01.png';
import ojoabierto from './img/ojoabierto.png';
import ojocerrado from './img/ojocerrado.png';
import llama from './img/llama.png';
import vela from './img/vela.png';
import capa01 from './img/capa01.png';
import ojo01 from './img/ojo01.png';
import cereza from './img/cereza.png';
import baba from './img/baba.png';
import loader from './img/loader.png';

// audios
import mananitas from './audios/PI_LM.mp3';


function App() {

  const [ocultar, setOcultar] = useState(true);
  const [teamo, setTeamo] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [navegador, setNavegador] = useState("");

  const audio = useRef('');

  const ocultarBoca = () => {
    console.log('click');
    setOcultar(!ocultar);
  }

  function teAmoEmiEnter (e){
    console.log('onmouseenter');
    setTeamo(true);
  }

  const teAmoEmiFuera = () => {
    setTeamo(false);
  }

  useEffect(() => {
    audio.current.play();
    function onImagesLoaded(container, event) {
      console.log(container);
      let images = container.getElementsByTagName("img");
      let loaded = images.length;
      for (let i = 0; i < images.length; i++) {
        if (images[i].complete) {
          loaded--;
        }
        else {
          images[i].addEventListener("load", function() {
              loaded--;
              if (loaded === 0) {
                setCargando(true);
                event();
              }
          });
        }
        if (loaded === 0) {
            setCargando(true);
            event();
        }
      }
    }

    const container = document.getElementById("cup");

    onImagesLoaded(container, function() {
      console.log("All the images have loaded");
    });

    const navegador = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;

    setNavegador(navegador);

    console.log(navegador);

  }, [cargando]);
  
  return (
    <Fragment>
      <div 
        id = "app"
        style = {cargando ? {"opacity": "1", "transition": "0.5s cubic-bezier(.22,.61,.36,1)", "overflow":""} : {"opacity": "0", "transition": "0.5s cubic-bezier(.22,.61,.36,1)", "overflow":"hidden"}}
      >
        <audio loop ref={audio}>
          <source src={mananitas} type="audio/mpeg"/>
          Your browser does not support the audio element.
        </audio>
        <header
          style = {navegador ? 
            {
              "backdrop-filter": "blur(8px)",
              "-webkit-backdrop-filter": "blur(8px)",
              "background-color": "rgba(7, 11, 15, 0.72)"
            } :
            {"background-color": "#070b0f"}
          }
        >
          <div>
            <p>¡Feliz cumpleaños</p>
            <p>
              <a href="https://a82020.besprone.com.mx/" target = '_blank' rel="noopener noreferrer">
                Emi!
              </a>
            </p>
          </div>
        </header>
        <div className = "contentImg">
          <section className = "cup" id = "cup">
            <div className = "papel img">
              <img src = {papel} alt = 'papel'/>
            </div>
            <div 
              className = "corazon img"
              onMouseEnter = {e => {teAmoEmiEnter(e)}}
              onMouseLeave = {e => {teAmoEmiFuera(e)}}
            >
              <img src = {corazon} alt = 'imgCorazon'/>
            </div>
            <div className = "pan img">
              <img src = {pan} alt = 'pan'/>
            </div>
            <div className = "cubierta img">
              <img src = {cubierta} alt = 'cubierta'/>
            </div>
            <div 
              className = "boca02 img"
              onClick = {ocultarBoca}
              style = {ocultar ? {'display': 'none'} : {'display': 'block'}}
            >
              <img src = {boca02} alt = 'boca02'/>
            </div>
            <div 
              className = "baba img"
              style = {ocultar ? {'display': 'none'} : {'display': 'block'}}
            >
              <img src = {baba} alt = 'baba'/>
            </div>
            <div 
              className = "boca01 img"
              onClick = {ocultarBoca}
              style = {ocultar ? {'display': 'block'} : {'display': 'none'}}
            >
              <img src = {boca01} alt = 'boca01'/>
            </div>
            <div className = "ojocerrado img">
              <img src = {ojocerrado} alt = 'ojocerrado'/>
            </div>
            <div className = "ojoabierto img">
              <img src = {ojoabierto} alt = 'ojoabierto'/>
            </div>
            <div className = "llama img">
              <img src = {llama} alt = 'llama'/>
            </div>
            <div className = "vela img">
              <img src = {vela} alt = 'vela'/>
            </div>
            <div className = "capa01 img">
              <img src = {capa01} alt = 'capa01'/>
            </div>
            <div className = "ojo01 img">
              <img src = {ojo01} alt = 'ojo01'/>
            </div>
            <div className = "cereza img">
              <img src = {cereza} alt = 'cereza'/>
            </div>
            {teamo ? 
              <div
                className = "teamo"
                style = {navegador ? 
                  {
                    "backdrop-filter": "blur(8px)",
                    "-webkit-backdrop-filter": "blur(8px)",
                    "background-color": "rgba(7, 11, 15, 0.72)"
                  } :
                  {"background-color": "rgba(7, 11, 15, 0.72)"}
                }
              >
                <p>¡Te amo Emi! {'<3'}</p>
              </div> :
              ""
            }
          </section>
        </div>
        <footer
          style = {navegador ? 
            {
              "backdrop-filter": "blur(8px)",
              "-webkit-backdrop-filter": "blur(8px)",
              "background-color": "rgba(7, 11, 15, 0.64)"
            } :
            {"background-color": "#070b0f"}
          }
        >
          <p>
            <a href="https://besprone.com.mx" target = '_blank' rel="noopener noreferrer">
              emiandbesprone.com
            </a>
          </p>
        </footer>
    </div>
    { !cargando ?
      <div className = "cargando">
        <div className = "loaderOjo">
          <img src = {loader} alt = 'cargando'/>
        </div>
        <p>Cargando...</p>
      </div> :
      null
    }
    </Fragment>
  );
}

export default App;
